<template>
  <div class="main">
    <Slider></Slider>
    <div class="main-ss">
      <div class="cert-main">
        <div class="search">
          <div class="title">证书查询</div>
          <input type="text" placeholder="支持全球GIA、HRD、IGI证书" ref="certNo" :value="input">
          <div class="btn" :class="selclick ? 'sel' : ''" @click="search">查询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import Slider from '@/components/slider/slider'
  export default {
    name: 'certSearch',
    components:{
      Slider
    },
    data () {
      return {
        input: '',
        relationId: '',
        selclick: false,
      }
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        let relationId = ''
        if (shop && this.$store.state.SequenceNo) {
          relationId = shop.relationId
        }
        return relationId
      }
    },
    watch: {
      shopInit: {
        handler (newValue, oldValue) {
          if (this.relationId != '' || newValue == '') return
          this.relationId = newValue
          if (newValue != '') {
            let shop = this.$store.state.shop
            this.$getif(shop.vipType)
            // this.getInit()
          }
        },
        immediate: true,
      }
    },
    methods: {
      search () {
        let certNo = this.$refs.certNo.value
        if (certNo == '') {
          this.$message.error('证书号不能为空');
          return
        }
        if (!(/^[0-9]{8,12}$/.test(certNo))) {
          this.$message.error('请填写正确的证书号');
          return
        }

        this.input = certNo

        this.selclick = true
        this.selclick = false
        window.open(`/certDetails?certNo=${certNo}`)

        // setTimeout(() => {
        //   // this.$router.push({
        //   //   path: '/certDetails',
        //   //   query: {
        //   //     certNo: certNo
        //   //   }
        //   // })
        // }, 1500)
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    height: calc(100vh - 360px);
    min-height: 512px;
    background: #242526;
    width: 100%;
  }

  .main-ss{
    height: calc(100vh - 360px);
    min-height: 512px;
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    background-image: url("https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/cert_bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .cert-main {
    width: 515px;
    margin: 0 auto;
    text-align: center;

    .title {
      width: 100%;
      font-size: 23px;
      color: #F5B5A9;
      position: absolute;
      top: -60px;
    }

    .search {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      input {
        width: 418px;
        height: 50px;
        line-height: 50px;
        border: 1px solid #CCCCCC;
        padding-left: 20px;
        font-size: 14px;
        color: #333333;
      }

      input::-webkit-input-placeholder {
        color: #999999;
      }

      .btn {
        width: 87px;
        height: 50px;
        background: #FF8178;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 50px;
        cursor: pointer;
        position: relative;

        img{
          width: 20px;
          height: 20px;
        }

        &.sel{
          &:before{
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            background-image: url("https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/btnLoading.png");
            background-size: 100%;
            background-repeat: no-repeat;
            top: 17px;
            left: 10px;
            animation: myRotate .4s linear infinite;
          }
        }
      }
    }
  }
  @import '@/assets/css/main.scss';
</style>
